import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { GlobalStateContext } from '../containers/GlobalContextProvider'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'

const detailsQuery = graphql`
  query SEOQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title {
        _type
        sl
        en
      }
      description {
        _type
        sl
        en
      }
      defaultShareImage {
        asset {
          _id
        }
      }
      keywords
    }
  }
`

const buildShareImageURL = sanityImage => {
  if (!sanityImage) return ''
  return imageUrlFor(buildImageObj(sanityImage))
    .format('png')
    .quality('100')
    .width(1360)
}

function SEO(props) {
  const pageContext = useContext(GlobalStateContext)
  const { description, meta, keywords = [], title } = props
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        if (!data.site) {
          return
        }
        const metaDescription = description || data.site.description[pageContext.locale]
        const siteTitle = data.site.title[pageContext.locale]

        const shareImage =
          buildShareImageURL(props.image) || buildShareImageURL(data.site.defaultShareImage)
        // <link rel="preload" href="fonts/cicle_fina-webfont.woff2" as="font" type="font/woff2" crossorigin>
        return (
          <Helmet
            htmlAttributes={{
              lang: pageContext.locale
            }}
            title={title}
            titleTemplate={title === siteTitle ? '%s' : `%s | ${siteTitle}`}
            link={[
              {
                rel: 'apple-touch-icon',
                sizes: '180x180',
                href: '/favicons/apple-touch-icon.png'
              },
              {
                rel: 'icon',
                sizes: '32x32',
                href: '/favicons/favicon-32x32.png'
              },
              {
                rel: 'icon',
                sizes: '16x16',
                href: '/favicons/favicon-16x16.png'
              },
              {
                rel: 'manifest',
                href: '/favicons/site.webmanifest'
              },
              {
                rel: 'preload',
                href: '/fonts/cherrydorry-export/cherrydorry.css',
                as: 'style'
              },
              {
                rel: 'preload',
                href: '/fonts/rama/3ADE9A_6_0.woff2',
                as: 'font',
                type: 'font/woff2'
              },
              {
                rel: 'preload',
                href: '/fonts/rama/3ADE9A_A_0.woff2',
                as: 'font',
                type: 'font/woff2'
              },
              {
                rel: 'preload',
                href: '/fonts/rama/3ADE9A_D_0.woff2',
                as: 'font',
                type: 'font/woff2'
              },
              {
                rel: 'preload',
                href: '/fonts/cherrydorry-export/cherrydorry.woff2',
                as: 'font',
                type: 'font/woff2'
              }
            ]}
            meta={[
              {
                name: 'viewport',
                content:
                  'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
              },
              {
                name: 'google-site-verification',
                content: 'EnV__CXP2IosdMURHMQspyJArQpJ2ml6-nSVbn4ASb8'
              },
              {
                name: 'description',
                content: metaDescription
              },
              {
                property: 'og:image',
                content: shareImage
              },
              {
                property: 'og:title',
                content: title
              },
              {
                property: 'og:description',
                content: metaDescription
              },
              {
                property: 'og:type',
                content: 'website'
              },
              {
                name: 'twitter:card',
                content: 'summary'
              },
              {
                name: 'twitter:creator',
                content: data.site.author
              },
              {
                name: 'twitter:title',
                content: title
              },
              {
                name: 'twitter:description',
                content: metaDescription
              },
              {
                name: 'msapplication-TileColor',
                content: '#d80055'
              },
              {
                name: 'theme-color',
                content: '#fbfcfc'
              }
            ]
              .concat(
                keywords && keywords.length > 0
                  ? {
                      name: 'keywords',
                      content: keywords.join(', ')
                    }
                  : []
              )
              .concat(meta)}
          />
        )
      }}
    />
  )
}

SEO.defaultProps = {
  meta: [],
  keywords: []
}

SEO.propTypes = {
  description: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired
}

export default SEO
